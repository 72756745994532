.App {
  text-align: center;
  background-color: rgb(55, 55, 55);
  height: 100vh;
  width: 100vw;
  font-family: var(--font-main);
  color: white;
}

.header__co {
    justify-content: space-around;
    width: 100%;
}
#afekcii-logo {
    width: 50px; height: 50px;
}