@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

:root {
  --font1:'Permanent Marker', cursive;
  --font2:'Kalam', cursive;
  --font3: 'Special Elite', cursive;
  --font4:'Rock Salt', cursive;
  --font5:'Black Ops One', cursive;
  --font6:'Fredericka the Great', cursive;

}
:root {
  --color-main: red;
  --color-bg-main: rgb(51, 51, 51);
  --color-bg-second: rgb(69, 69, 69);
  --color-second: #EFC050;
  --color-second-Opac: #EFC050;
  --color-text-white:white;
  --color-text-whiteOpac:rgb(188, 188, 188);
  --font-main: 'Titillium Web', sans-serif;
  --icon-font: 'Mr Dafoe', cursive;   /*'Audiowide', cursive;*/
  --color-hover: #EFC050
}
html, body {overflow-x: hidden !important;width: 100vw !important; max-width: 100vw !important;}
html {
  
  
}
#root {
    overflow-x: hidden !important
}
a {
  text-decoration: none !important;
  color: inherit;
}
body {
  padding: 0;
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
 
  
}






/* ICON */
.main-icon h1{
  font-family: var(--icon-font);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.main-icon:hover h1{
  color: var(--color-hover);
}

.overflow-p{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
}
.overflow-wrap-p{
  text-overflow: ellipsis;
  overflow: hidden;
  
  
}









/* CONTAINER SETTINS*/
.con__def {
  display: flex;
  flex-direction: column;
  height: 100%; min-height: 100vh;
  width: 100vw;
}
.flex__center-r {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex__center-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-c {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
}
.flex__r-c {
}
@media screen and (max-width: 650px) {
    .flex__r-c {
        flex-direction: column;
    }
}







h1,h2,h3,h4,h5,h6,p {
    font-family:'monospace',sans-serif;
}


/* FULL IMAGE 100vh */ 
.full-page-img { 
  margin: 0;
  padding: 0;
  height: 100vh;   
  background-size: cover; 
  background-repeat: no-repeat;  
}
.header-img {
  filter: brightness(0.5);
  top: 0; left: 0; right: 0; bottom: 0;
  position: absolute;
  object-fit: cover;
  width: 100vw;
  min-height: 250vh;
  z-index: -1;
  transform: translate3d(0,0,0);  
}
.section {
    height: 100vh;
    width: 80vw;
}



/* Map icon  */
.mapicon path{
/*     fill:white !important; */
    stroke:white !important;
    stroke-width: 0.1vmax !important;

    
}
.mapicon {
    /* transform: rotate(-145deg); */
}


/* ARROW */
/* ARROW bottom right*/
.intro__arrow {
  bottom: 50px; right: 50px;
  position: absolute;
}
.intro__arrow #react-icon-intro_arrow{
  cursor: pointer;
  height: 60px; width: 60px;
  color: rgba(255, 255, 255, 0.411);
  transition: color 0.4s ease-in-out;
}
.intro__arrow:hover #react-icon-intro_arrow{    
  color: var(--color-hover);
  
}



















/* LOADING SCREEN */ 
.loadingscreen {
  position: fixed;
  backdrop-filter: blur(10px);
  background-color: rgba(41, 41, 41, 0.866);

  width: 100vw; height: 100vh;
  top: 0; left: 0; right : 0; bottom : 0;
  
  z-index: 15;
  
}
.loadingscreen h5 {
  margin-top: 2rem;
  color: var(--color-second);
  
}
.loadingscreen h1 {  
  font-size: 45px;
  color: var(--color-second);
  
}

.loadingscreen__container {
  position: relative;
}
.loadingscreen__circle {
  position: absolute;
 /*  top: 62px; */
  left: 67px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: var(--color-second);
  
}
.loadingscreen__orbit {
  position: absolute;
  top: -25px;
  left: -25px;
  width: 150px;
  height: 150px;
  border: 2px #ffffff00 dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 2s ease-in-out infinite;
  animation: spin-right 2s ease-in-out infinite;
  
}

@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}






